import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import DateField from '@/shared/fields/date-field';
import IdField from '@/shared/fields/id-field';

const field = (name) => i18n.t(`ARCHIVE.DOCUMENTS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`ARCHIVE.SUBFOLDER.PLACEHOLDERS.${name.toUpperCase()}`);


const fields = {
    documentId: new IdField('documentId', 'documentId'),
    documentName: new StringField(
        'documentName',
        field('document_name'),
    ),
    documentTypeName: new StringField(
        'documentTypeName',
        field('document_type_name'),
    ),
    documentSize: new StringField(
        'documentSize',
        field('document_size'),
    ),
    documentCreatedDate: new DateField(
        'documentCreatedDate',
        field('created_date'),
    ),
    documentLastModifiedDate: new DateField(
        'documentLastModifiedDate',
        field('updated_date'),
    ),
};

export class DocumentModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
