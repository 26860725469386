const calcSortArchiveParams = (params) => {
    const { sortBy, sortDesc } = params;
    if (sortBy) {
        return {
            isAscendingOrder: !sortDesc,
            sortField: sortBy
        }
    }
    return {};
}

export default calcSortArchiveParams;
