<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="loading['archive/getDocuments']"
            :serverSideSorting="true"
            @view="onView"
            stateModule="archive/documents"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { DocumentModel } from '@/modules/archive/models/document-model';

    const { fields } = DocumentModel;

    export default {
        name: 'DocumentsListTable',
        props: {
            titlePath: {
                type: String,
                required: true,
            },
            selectedWorkspace: {
                type: Object,
                required: true
            },
            selectedFolder: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                presenter: DocumentModel.presenter,
                fields: [
                    fields.documentName.extendField({ bold: true, sortable: true }),
                    fields.documentTypeName.extendField({ bold: true, sortable: true }),
                    fields.documentSize.extendField({ sortable: true }),
                    fields.documentCreatedDate.extendField({ sortable: true }),
                    fields.documentLastModifiedDate.extendField({ sortable: true }),
                ],
                actions: [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'archive/documents/rows',
                loading: 'shared/loading',
            }),
        },
        methods: {
            onView(data) {
                this.$router.push({
                    name: 'archive-document',
                    params: {
                        id: data.item.documentId,
                        document: data.item,
                        titlePath: this.titlePath,
                        folder: this.selectedFolder,
                        workspace: this.selectedWorkspace
                    }
                });
            },
        },
    };
</script>
