<template>
    <st-page
        :title="$t('ARCHIVE.DOCUMENT.TITLE_MANUAL_ARCHIVE')"
    >
        <template #toolbar>
            <st-button
                variant="light"
                class="st-btn-text-primary font-size-h6 px-5 py-3 my-3 mr-3"
                :callback="cancel"
            >
                <i class="fas fa-times svg-icon-primary"/>
                <span>{{$t('GENERAL.BUTTON.CANCEL')}}</span>
            </st-button>
            <st-button
                variant="primary"
                class="font-size-h6 px-5 py-3 my-3 mr-3"
                :callback="doManualArchive"
            >
                <span>{{$t('GENERAL.BUTTON.SAVE')}}</span>
            </st-button>
        </template>
       <document
            ref="document"
            :folder="selectedFolder"
            :workspace="selectedWorkspace"
            @manualArchiveUpdate="manualArchiveUpdate"
        >
        </document>
    </st-page>
</template>

<script>
   import Document from '../components/document/DocumentBody';
    export default {
        name: 'ArchiveDocumentManual',
        components: {Document},
        props: {
            selectedFolder: {
                type: Object,
                required: true
            },
            selectedWorkspace: {
                type: Object,
                required: true
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel');
            },
            doManualArchive() {
                this.$alert({
                type: 'warning',
                    text: this.$t('ARCHIVE.CONFIRMATION_TEXT'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                        this.$refs['document'].submit();
                    }
                });
            },
            manualArchiveUpdate() {
                this.cancel();
            }
        },
    }
</script>
