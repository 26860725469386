<template>
    <st-filters-pagination
        stateModule="archive/documents"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :moreFilters="false"
        :showErase="false"
        :header="selectedFolder.name"
        :calcParams="calcArchiveParams"
        :calcSortParams="calcSortArchiveParams"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import calcArchiveParams from '@/shared/utils/calc-archive-query-params';
    import calcSortArchiveParams from '@/shared/utils/calc-archive-sort-params';
    import { FilterSchema } from '@/shared/form/filter-schema';
    const filterSchema = new FilterSchema([]);

    export default {
        name: 'DocumentsListFilter',
        props: {
            selectedFolder: {
                type: Object,
                required: true
            },
            selectedWorkspace: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                filters: filterSchema.fields,
                calcArchiveParams,
                calcSortArchiveParams
            }
        },
        computed: {
            ...mapGetters({
               total: 'archive/documents/total',
            }),
        },
        watch: {
            selectedFolder(value) {
                this.refresh()
            }
        },
        methods: {
            ...mapActions({
                doFetch: 'archive/documents/getDocuments',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                // TODO: get selectedWorkspace.realName from folder
                this.params = params;
                this.doFetch({
                   payload: params,
                   workspaceId: this.selectedWorkspace.realName,
                   folderId: this.selectedFolder.id
                });
            },
        },
    };
</script>
