<template>
    <st-page
        :title="title"
        :pageCustomClass=achiveManualsectionClass
        >
        <template #toolbar>
            <st-button
                v-if="!isManualArchive && $route.params.folder.documentTypeCode"
                v-can:create="'archive'"
                variant="warning"
                :callback="doManualArchive"
                :disabled="loading['archive/getDocuments']"
                customClass="text-dark font-size-h5"
            >
                <i class="fas fa-plus text-dark"/>
                {{$t('ARCHIVE.BUTTONS.MANUAL_ARCHIVE')}}
            </st-button>
        </template>
        <documents-list-filter
            v-if="!isManualArchive"
            :selectedFolder="$route.params.folder"
            :selectedWorkspace="$route.params.workspace"
            ref="listDocumentsFilter"
        >
            <documents-list-table
                :selectedFolder="$route.params.folder"
                :selectedWorkspace="$route.params.workspace"
                :titlePath="title"
            />
        </documents-list-filter>
        <archive-document-manual
            :selectedFolder="$route.params.folder"
            :selectedWorkspace="$route.params.workspace"
            v-if="isManualArchive"
            @cancel="cancel"
            >
        </archive-document-manual>
    </st-page>
</template>

<script>
    import DocumentsListFilter from '../components/documents/DocumentsListFilter';
    import DocumentsListTable from '../components/documents/DocumentsListTable';
    import ArchiveDocumentManual from './ArchiveDocumentManual';
    import { mapGetters } from 'vuex';
    export default {
        name: 'DocumentsList',
        components: {
            DocumentsListFilter,
            DocumentsListTable,
            ArchiveDocumentManual
        },
        data() {
            return {
                isManualArchive: false,
            };
        },
        computed: {
            ...mapGetters({
                breadcrumbs: 'archive/workspaces/breadcrumbs',
                loading: 'shared/loading',
            }),
            title() {
                const path = this.breadcrumbs.join(' / ');
                return `${this.$t('ARCHIVE.SUBFOLDER.LIST.TITLE')} / ${path}`;
            },
            achiveManualsectionClass () {
                return this.isManualArchive ? 'st-page-archive-documents' : '';
            }
        },
        watch: {
            breadcrumbs(value) {
                this.isManualArchive = false;
            }
        },
        methods: {
            doManualArchive() {
                this.isManualArchive = true;
            },
            cancel() {
                this.isManualArchive = false;
            }
        }
    }
</script>
